import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.TEST_CONSULTANCY_SERVER_PREFIX}/export`
const endpoints = {
    exportExample: () => `${RESOURCE_ENDPOINT}/example`,
    exportExampleReport: () => `${RESOURCE_ENDPOINT}/example-report`,
    exportIncomeStatementReport: () => `${RESOURCE_ENDPOINT}/income-statement-report-export`,
}

export default class ExportTestConsultancyApi {
    public exportExample = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportExample();
        return HttpService.post(url, payload, params, headers);
    }

    public exportExampleReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportExampleReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportIncomeStatementReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportIncomeStatementReport();
        return HttpService.post(url, payload, params, headers);
    }
}

